import React from 'react'
import Portfolio from '../Portfolio'
import Div from '../Div'
import Slider from 'react-slick';

export default function PortfolioSlider() {
  const portfolioData = [
    {
      title: 'Real Estate Site',
      subtitle: 'Lets easy find property, plots etc',
      href: '',
      src: '/images/portfolio_10.jpg'
    },
    {
      title: 'Upvc Software',
      subtitle: 'Design any type of uPVC Windows & Doors, get its costing and Invetory details.',
      href: '',
      src: '/images/portfolio_7.png'
    },
    {
      title: 'Hospital Invertory system',
      subtitle: 'Developed for MMH to manage their A to Z invetory',
      href: '',
      src: '/images/portfolio_6.png'
    },
    {
      title: 'CallZipper Web App',
      subtitle: 'A freelance marketplace Web App with google Map & Real time Chat',
      href: '',
      src: '/images/portfolio_9.png',
    },
  ]

  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings} className='cs-slider cs-style3 cs-gap-24'>
      {portfolioData.map((item, index) => (
        <Div key={index}>
          <Portfolio
            title={item.title}
            subtitle={item.subtitle}
            href={item.href}
            src={item.src}
          />
        </Div>
      ))}
    </Slider>
  )
}
