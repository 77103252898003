import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialThumb: '/images/testimonial_1.png',
      testimonialText:
        `Working with TechSol was an absolute game-changer for our business. Their innovative software solution streamlined our operations, saving us time and resources. We couldn't be happier with the incredible results they delivered.`,
      avatarName: 'Emily Johnson',
      avatarDesignation: 'Project Manager',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/testimonial_2.png',
      testimonialText:
        `Choosing TechSol was the best decision we made. Their team of experts developed a custom software application that exceeded our expectations. Their attention to detail and commitment to excellence truly made a difference in our day-to-day operations.`,
      avatarName: 'William Davis',
      avatarDesignation: 'Product Manager',
      ratings: '4.7',
    },
    {
      testimonialThumb: '/images/testimonial_3.png',
      testimonialText:
        `We were blown away by the level of professionalism and expertise displayed by TechSol. Their software solution revolutionized our workflow, increasing our productivity and efficiency. We highly recommend their services to any company seeking exceptional software development.`,
      avatarName: 'Olivia Taylor',
      avatarDesignation: 'CEO',
      ratings: '4.8',
    },
    {
      testimonialThumb: '/images/testimonial_4.png',
      testimonialText:
        `Thanks to TechSol, we now have a cutting-edge software platform that has transformed our business. The team's deep understanding of our industry and their ability to translate our vision into reality was remarkable. Their software has become an invaluable asset to our company's growth and success.`,
      avatarName: 'Benjamin Brown',
      avatarDesignation: 'CEO',
      ratings: '4.9',
    },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
