import React from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({ withIcon, title }) {
  const openWhatsApp = () => {
    window.open("https://api.whatsapp.com/send?phone=923409292792", "_blank");
  }
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li onClick={() => openWhatsApp()} style={{ cursor: 'pointer' }}>
          {withIcon ? <span className='cs-accent_color'><Icon icon="bx:bxl-whatsapp" /></span> : ''}
          Direct WhatsApp Us
        </li>
        <li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span> : ''}
          +92 340 9292792
        </li>
        <li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="mdi:envelope" /></span> : ''}
          technsol28@gmail.com
        </li>
        <li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span> : ''}
          Lane 4, Street 1,Peshawar road, <br />Rawalpindi/Islamabad Pakistan
        </li>
      </ul>
    </>
  )
}
